import { Select } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { lookupSearchById, lookupSearchByTerm } from './api';
import { MetadataLinks } from '@d19n/models/dist/schema-manager/metadata.links';
import { SchemaAssociationEntity } from '@d19n/models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaAssociationSchemaTypesConstraintEntity } from '@d19n/models/dist/schema-manager/schema/association/constraint/schema.association.schema.types.constraint.entity';
import { IRecordAssociationsReducer } from '../../../../../recordsAssociations/store/reducer';
import {
  ISearchRecordAssociations,
  searchRecordAssociationsRequest,
} from '../../../../../recordsAssociations/store/actions';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { RelationTypeEnum } from '@d19n/models/dist/schema-manager/db/record/association/types/db.record.association.constants';

const { Option } = Select;

interface Props {
  processId?: string;
  initialValue?: string;
  initialRecordMetadataLink?: MetadataLinks;
  schemaId: string;
  schemaAssociation: SchemaAssociationEntity;
  schemaTypesConstraint?: SchemaAssociationSchemaTypesConstraintEntity;
  onChange?: (value: string) => void;
  recordAssociationReducer: IRecordAssociationsReducer;
  searchAssociations: (params: ISearchRecordAssociations, cb?: any) => void;
  parentRecord?: DbRecordEntityTransform;
  onBlur?: any;
  onKeyDown?: any;
  autoFocus?: boolean;
}

const LookupInput: FC<Props> = (props: Props) => {
  const {
    schemaAssociation,
    schemaId,
    parentRecord,
    processId,
    onChange,
    onBlur,
    autoFocus,
    onKeyDown,
    initialRecordMetadataLink,
    initialValue,
    schemaTypesConstraint,
    searchAssociations,
    recordAssociationReducer,
  } = props;
  const [inputValue, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [data, setData] = useState<DbRecordEntityTransform[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  // On component mount, if initialValue is passed, search for it and set it as value in state.
  // Else, search for everything.
  useEffect(() => {
    if (initialValue && initialRecordMetadataLink) {
      setValue(initialValue);
      searchForInitialValue();
    } else {
      setSearchValue('*');
    }
  }, []);

  // On every searchValue update, search for it
  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    }
  }, [searchValue]);

  // Helper functions
  const getRelatedSchema = () => {
    return schemaAssociation.parentSchemaId === schemaId
      ? schemaAssociation.childSchema
      : schemaAssociation.parentSchema;
  };
  const getRelatedType = () => {
    return schemaAssociation.parentSchemaId === schemaId
      ? RelationTypeEnum.CHILD
      : RelationTypeEnum.PARENT;
  };

  const handleChange = (value: string) => {
    setValue(value);
    if (onChange) onChange(value);
  };

  const handleClear = () => {
    setIsDisabled(true);
    setValue('');
    setData([]);
    if (searchValue === '*') {
      handleSearch('*');
    } else {
      setSearchValue('*');
    }
    if (onChange) onChange('');
  };

  // When user opens up a dropdown, reset search to * if there is a searchValue
  // and inputValue. We want to show all search results again
  const resetSearch = () => {
    if (searchValue !== '*' && inputValue) {
      setValue('');
      setSearchValue('*');
      if (onChange) onChange('');
    }
  };

  // This is searching for initialValue and setting the value, happens when there is a initialValue and component is freshly mounted
  const searchForInitialValue = () => {
    const relatedSchema = getRelatedSchema();
    const relatedType = getRelatedType();
    setIsSearching(true);

    // Configure query to search for initial value (id)
    let boolean: any = {
      must: [
        {
          match: {
            id: initialValue,
          },
        },
      ],
    };

    // Add schema types filter if needed
    if (schemaTypesConstraint) {
      const type =
        relatedType === RelationTypeEnum.CHILD
          ? schemaTypesConstraint.childSchemaType?.name
          : schemaTypesConstraint.parentSchemaType?.name;
      if (type) {
        boolean.must.push({
          match: {
            type: type,
          },
        });
      }
    }

    lookupSearchById(
      relatedSchema!,
      parentRecord?.id,
      initialRecordMetadataLink!.id,
    ).then((res: any) => {
      setData(res);
      setIsDisabled(false);
      setIsSearching(false);
    });
  };

  // Search associations for text
  const handleSearch = (text: string) => {
    setIsSearching(true);
    const relatedSchema = getRelatedSchema();
    const relatedType = getRelatedType();

    // Add type to query if needed
    let boolean: any;
    if (schemaTypesConstraint) {
      const type =
        relatedType === RelationTypeEnum.CHILD
          ? schemaTypesConstraint.childSchemaType?.name
          : schemaTypesConstraint.parentSchemaType?.name;

      if (type) {
        boolean = {
          must: [
            {
              match: {
                type: type,
              },
            },
          ],
        };
      } else {
        boolean = {};
      }
    }

    lookupSearchByTerm({
      recordId: parentRecord?.id || undefined,
      processId: processId,
      schema: relatedSchema!,
      schemaAssociation: schemaAssociation,
      searchQuery: {
        terms: text,
        boolean,
        schemas: relatedSchema!.id,
        pageable: {
          page: 1,
          size: 100,
        },
        sort: recordAssociationReducer.searchQuery.sort,
      },
    }).then((res: any) => {
      setData(res);
      setIsDisabled(false);
      setIsSearching(false);
    });
  };

  const renderSelectOptions = () => {
    if (isSearching) {
      return (
        <Option key="loading" value="Loading" disabled>
          Loading...
        </Option>
      );
    } else {
      return data.map((option: any) => (
        <Option key={option.id} value={option.id}>
          {option.recordNumber ? `${option.recordNumber} - ` : ''}
          {option.title}
        </Option>
      ));
    }
  };

  return (
    <Select
      key={schemaAssociation.id}
      allowClear={true}
      autoFocus={autoFocus}
      defaultActiveFirstOption={false}
      filterOption={false}
      loading={isSearching}
      notFoundContent={null}
      onBlur={onBlur && onBlur}
      onChange={(e: any) => handleChange(e)}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
        onKeyDown && onKeyDown(e)
      }
      onSearch={(e: string) => setSearchValue(e)}
      onClear={handleClear}
      placeholder="Type at least 2 characters to search"
      showSearch
      value={inputValue}
      disabled={isDisabled}
      onDropdownVisibleChange={(open: boolean) => {
        open && resetSearch();
      }}
    >
      {renderSelectOptions()}
    </Select>
  );
};

const mapState = (state: any) => ({
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchAssociations: (params: ISearchRecordAssociations, cb: any) =>
    dispatch(searchRecordAssociationsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(LookupInput);
