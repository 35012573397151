import { Col, Layout, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { sendConfirmationEmail } from '../../../../../core/notifications/email/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import AssociationCardList from '../../../../../core/recordsAssociations/AssociationCardList';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import {
  getAllSchemaAssociationSchemas,
  getRecordFromShortListById,
} from '../../../../../shared/utilities/recordHelpers';
import './styles.scss';
import PlainRecordDetailView from '../../../../../shared/PlainRecordDetailView';
import {
  getSchemaFromShortListBySchemaId,
  schemaHasType,
} from '../../../../../shared/utilities/schemaHelpers';
import SABookingModal from '../../../../../core/appointments/components/SABookingModal/SABookingModal';
import ServiceAppointmentCancelModal from '../../../ServiceAppointmentCancelModal';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import OutcomeFormLauncher from '../../../../../core/records/components/OutcomeFormLauncher';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import PermissionToFailCard from './PermissionToFailCard';
import { Section, Tab, Tabs } from '@blueprintjs/core';
import OrderNetworkItems from '../../OrderNetworkItems';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { httpGet } from '../../../../../shared/http/requests';
import NoteFeed_LEGACY from '../../../../../core/records/components/NoteFeed_LEGACY';
import RecordProperties from '../../../../../core/records/components/RecordProperties';
import OutcomeFormList from '../../../../../core/records/components/OutcomeFormList';
import NoteFeed from '../../../../../core/records/components/NoteFeed';

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  sendConfirmation: any;
  match: any;
};

interface State {
  activeTab: string;
  relatedOrder: DbRecordEntityTransform | undefined;
}

const { ORDER } = SchemaModuleEntityTypeEnums;

class WorkOrderDetailView extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      activeTab: 'Devices',
      relatedOrder: undefined,
    };
  }

  componentDidMount() {
    this.getOrder();
  }

  componentDidUpdate(
    prevProps: Readonly<PropsType>,
    prevState: Readonly<State>,
    snapshot?: any,
  ): void {
    if (prevProps.match.params.recordId !== this.props.match.params.recordId) {
      this.getOrder();
    }
  }

  getOrder = () => {
    const { match } = this.props;

    const recordId = match.params.recordId;

    httpGet(
      `FieldServiceModule/v1.0/db-associations/WorkOrder/${recordId}/one-relation?entity=Order&withLinks=false`,
    )
      .then((res: any) => {
        this.setState({ relatedOrder: res.data['Order']?.dbRecords[0] });
      })
      .catch((err) => {
        console.error('Error loading tab data:', err);
      });
  };

  render() {
    const { recordReducer, schemaReducer, match } = this.props;
    const record = getRecordFromShortListById(
      recordReducer.shortList,
      match.params.recordId,
    );

    const schema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      record?.schemaId,
    );

    // Get related schemas and exclude these ones immediately
    let relatedSchemas = getAllSchemaAssociationSchemas(schema?.associations, [
      'Note',
      'File',
      'ServiceAppointment',
      'OrderItem',
      'ChangeReason',
    ]);

    // Certain schemas have OUTCOME_FORM type, find those schemas and filter them out from relatedSchemas
    const schemasWithOutcomeForm: SchemaEntity[] = relatedSchemas?.filter(
      (relatedSchema: SchemaEntity) =>
        schemaHasType(relatedSchema, 'OUTCOME_FORM'),
    );

    const backProps = { backUrl: '/FieldServiceModule/WorkOrder' };

    const SummaryPanel = () => {
      return (
        <>
          {/* SA */}
          <Col span={24} style={{ marginBottom: 16 }}>
            <AssociationCardList
              cardExtra={
                <span>
                  <SABookingModal record={record!} showAsButton />
                </span>
              }
              record={record}
              parentSchema={schema!}
              moduleName="FieldServiceModule"
              entityName="ServiceAppointment"
              layout="horizontal"
              showRecordTitle
              propKeys={['Date', 'TimeBlock']}
            />
          </Col>

          {/* Contact */}
          <Col span={24} style={{ marginBottom: 16 }}>
            <AssociationCardList
              record={record}
              parentSchema={schema!}
              moduleName="CrmModule"
              entityName="Contact"
              layout="horizontal"
              showRecordTitle
              propKeys={['Phone', 'Mobile', 'EmailAddress']}
            />
          </Col>

          {/* Address */}
          <Col span={24} style={{ marginBottom: 16 }}>
            <AssociationCardList
              record={record}
              parentSchema={schema!}
              moduleName="CrmModule"
              entityName="Address"
              layout="vertical"
              showRecordTitle
              propKeys={[
                'Type',
                'SalesStatus',
                'SubClassification',
                'ExPolygonId',
                'L1PolygonId',
                'L2PolygonId',
                'L4PolygonId',
                'L4ClosureId',
              ]}
            />
          </Col>

          {/* Order */}
          <Col span={24} style={{ marginBottom: 16 }}>
            <AssociationCardList
              record={record}
              parentSchema={schema!}
              moduleName="OrderModule"
              entityName="Order"
              layout="horizontal"
              showRecordTitle
              propKeys={[]}
            />
          </Col>
        </>
      );
    };

    const handleTabChange = (tab: string) => {
      this.setState({ activeTab: tab });
    };

    return (
      <Layout className="record-detail-view">
        <ServiceAppointmentCancelModal record={record!} />

        <Row gutter={8} className="record-main-content-row">
          <Col span={24} style={{ marginBottom: 8 }}>
            <PlainRecordDetailView {...backProps} />
          </Col>

          <Col span={24}>
            <Section>
              <div style={{ padding: 10 }}>
                <Tabs
                  large
                  id="WOTabs"
                  onChange={handleTabChange}
                  selectedTabId={this.state.activeTab}
                  renderActiveTabPanelOnly
                >
                  <Tab
                    id="Devices"
                    title="Devices"
                    panel={
                      <OrderNetworkItems record={this.state.relatedOrder!} />
                    }
                  />

                  <Tab id="Summary" title="Summary" panel={SummaryPanel()} />

                  <Tab
                    id="Details"
                    title="Details"
                    panel={
                      <RecordProperties
                        record={record}
                        columns={1}
                        showMoreByDefault
                      />
                    }
                  />

                  <Tab
                    id="Notes"
                    title="Notes"
                    panel={<NoteFeed record={record} />}
                  />

                  <Tab
                    id="OF"
                    title="OF"
                    panel={
                      <Row>
                        <Col span={24}>
                          <OutcomeFormLauncher
                            parentRecord={record!}
                            parentSchema={schema!}
                          />
                        </Col>
                        <Col span={24}>
                          <OutcomeFormList
                            parentRecord={record!}
                            parentSchema={schema!}
                          />
                        </Col>
                      </Row>
                    }
                  />
                </Tabs>
              </div>
            </Section>
          </Col>
          <Col span={24} style={{ margin: '10px 0 20px 0' }}>
            {/* Permission to fail */}
            <PermissionToFailCard record={record} />
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
});

export default withRouter(connect(mapState, mapDispatch)(WorkOrderDetailView));
