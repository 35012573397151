import { CheckSquareTwoTone, CloseSquareTwoTone } from '@ant-design/icons';

import { Col, Descriptions, Empty, Popconfirm, Row, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { displayMessage } from '../../../../shared/system/messages/store/reducers';
import { changeToCapitalCase } from '../../../../shared/utilities/dataTransformationHelpers';
import { getModuleAndEntityNameFromRecord } from '../../../../shared/utilities/recordHelpers';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '../../../../shared/utilities/schemaHelpers';
import {
  getSchemaByIdRequest,
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../schemas/store/actions';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import {
  setRawDataDrawerContents,
  toggleRawDataDrawer,
} from '../../../userInterface/store/actions';
import './styles.scss';
import {
  canUserUpdateRecord,
  hasPermissions,
  isSystemAdmin,
} from '../../../../shared/permissions/rbacRules';

import {
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '../../store/actions';

import renderFormField, { FormField } from '../Forms/FormFields';
import utc from 'dayjs/plugin/utc';
import { DateTime } from 'luxon'; // Import the UTC plugin
import { Button } from '@blueprintjs/core';
import {
  IUpdateRelatedRecordAssociation,
  updateRecordAssociationRequest,
} from '../../../recordsAssociations/store/actions';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { IGetSchemaById } from '@d19n/models/dist/rabbitmq/rabbitmq.interfaces';
import { SchemaColumnEntity } from '@d19n/models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaColumnTypes } from '@d19n/models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaColumnOptionEntity } from '@d19n/models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { SchemaAssociationEntity } from '@d19n/models/dist/schema-manager/schema/association/schema.association.entity';

dayjs.extend(utc); // Extend Day.js with the UTC plugin

interface Props {
  schemaReducer: ISchemaReducer;
  userReducer: any;
  record: DbRecordEntityTransform | undefined;
  columns?: number;
  visibleProperties?: string[];
  hiddenProperties?: string[];
  size?: 'small' | 'smaller';
  enableCopyToClipboard?: boolean;
  alertMessage: Function;
  toggleDrawer: Function;
  setRawDataDrawer: Function;
  getSchema: Function;
  getSchemaById: Function;
  showCreatedAt?: boolean;
  showUpdatedAt?: boolean;
  additionalFields?: additionalField[];
  onUpdate: () => void;
  updateRecord: (params: IUpdateRecordById, cb: any) => void;
  labelStyle?: any;
  contentStyle?: any;
  showMoreByDefault?: boolean;
  hasColumnMappings?: boolean;
  updateRecordAssociation: (
    params: IUpdateRelatedRecordAssociation,
    cb?: any,
  ) => void;
}

type additionalField = {
  label: string;
  value: string;
};

interface State {
  schema: SchemaEntity | undefined;
  propertiesExpanded: boolean;
  openedTooltip: string | undefined;
  openedWarning: string | undefined;
  changedProperty: any;
  isSavingEdit: boolean;
}

const defaultPropertiesNum = isMobile ? 5 : 15;

class RecordProperties extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      schema: undefined,
      propertiesExpanded: this.props.showMoreByDefault || false,
      openedTooltip: undefined,
      changedProperty: undefined,
      isSavingEdit: false,
      openedWarning: undefined,
    };
  }

  loadSchema() {
    const { record, schemaReducer, getSchema, getSchemaById } = this.props;

    if (record?.schemaId) {
      const shortlistSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        record?.schemaId,
      );

      // Access shortlist schema
      if (shortlistSchema) {
        this.setState({ schema: shortlistSchema });
      }
      // Or fallback to API request
      else {
        getSchemaById({ schemaId: record?.schemaId }, (response: any) => {
          this.setState({ schema: response });
        });
      }
    } else if (record?.entity) {
      const moduleEntity = getModuleAndEntityNameFromRecord(record);

      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleEntity.moduleName,
        moduleEntity.entityName,
      );

      // Access shortlist schema
      if (shortlistSchema) {
        this.setState({ schema: shortlistSchema });
      }
      // Or fallback to API request
      else {
        getSchema(
          {
            moduleName: moduleEntity.moduleName,
            entityName: moduleEntity.entityName,
          },
          (response: SchemaEntity) => {
            this.setState({ schema: response });
          },
        );
      }
    }
  }

  componentDidMount() {
    this.loadSchema();
  }

  // Record passed somewhere after the component mount
  componentDidUpdate(prevProps: any, prevState: any) {
    if (!prevProps.record && this.props.record && !this.state.schema) {
      this.loadSchema();
    }

    if (!prevState.openedWarning && this.state.openedWarning) {
      setTimeout(() => {
        this.setState({ openedWarning: undefined });
      }, 1500);
    }
  }

  copyValueToClipboard = (value: any, message: string) => {
    const { alertMessage } = this.props;
    navigator.clipboard.writeText(value);
    alertMessage({ body: message, type: 'success' });
  };

  // If no column argument is passed, check if mobile and use 2 columns there.
  getNumberOfColumns = (columns: number) => {
    if (!columns) {
      return isMobile ? 2 : 4;
    } else {
      return columns;
    }
  };

  renderListItemContent() {
    const {
      record,
      columns,
      visibleProperties,
      additionalFields,
      hiddenProperties,
      labelStyle,
      contentStyle,
    } = this.props;

    if (record && record.properties && this.state.schema) {
      let propsToRender;
      const { schema } = this.state;

      if (visibleProperties && visibleProperties?.length > 0) {
        propsToRender = Object.keys(record?.properties).filter((elem) =>
          visibleProperties ? visibleProperties.includes(elem) : true,
        );
      } else if (hiddenProperties && hiddenProperties?.length > 0) {
        propsToRender = Object.keys(record?.properties).filter((elem) =>
          hiddenProperties ? !hiddenProperties.includes(elem) : true,
        );
      } else {
        propsToRender = Object.keys(record?.properties);
      }

      // We want to show Contract Start and End date in proper sequence, until we figure out
      // how to move away from alphabetically sorted record properties.
      if (
        propsToRender.includes('ContractStartDate') &&
        propsToRender.includes('ContractEndDate')
      ) {
        const ContractStartDatePos = propsToRender.indexOf('ContractStartDate');
        const ContractEndDatePos = propsToRender.indexOf('ContractEndDate');
        propsToRender.splice(ContractEndDatePos, 1);
        propsToRender.splice(ContractStartDatePos, 0, 'ContractEndDate');
      }

      return (
        <Row>
          <Col span={24}>
            <Descriptions
              labelStyle={{
                fontSize: '0.9em',
                padding: '4px 6px',
                fontWeight: 500,
                width: '20%',
                ...labelStyle,
              }}
              contentStyle={{
                fontSize: '0.9em',
                padding: '4px 6px',
                width: '80%',
                ...contentStyle,
              }}
              className={`recordProperties_${this.props.size}`}
              column={this.getNumberOfColumns(columns!)}
              layout="horizontal"
              size="small"
              bordered={true}
            >
              {propsToRender
                .slice(
                  0,
                  this.state.propertiesExpanded ||
                    propsToRender.length <= defaultPropertiesNum
                    ? propsToRender.length
                    : defaultPropertiesNum,
                )
                .map((elem) =>
                  this.renderDescriptionItemSimple(
                    elem,
                    getProperty(record, elem, true),
                  ),
                )}

              {additionalFields && additionalFields?.length > 0 ? (
                additionalFields.map((field: additionalField) => (
                  <Descriptions.Item key={field.label} label={field.label}>
                    {field.value || '-'}
                  </Descriptions.Item>
                ))
              ) : (
                <></>
              )}

              {/* Show Created at */}
              {this.props.showCreatedAt ? (
                <Descriptions.Item key={'CreatedAtKey'} label="Created at">
                  {/*@ts-ignore*/}
                  {DateTime.fromISO(record.createdAt).toFormat(
                    'M/d/yyyy h:mm a ZZZZ',
                  )}
                </Descriptions.Item>
              ) : (
                <></>
              )}

              {/* Show Updated at */}
              {this.props.showUpdatedAt ? (
                <Descriptions.Item key={'UpdatedAtKey'} label="Updated at">
                  {/*@ts-ignore*/}
                  {DateTime.fromISO(record.updatedAt).toFormat(
                    'M/d/yyyy h:mm a ZZZZ',
                  )}
                </Descriptions.Item>
              ) : (
                <></>
              )}
            </Descriptions>

            {/* Show More / Less Button */}
            <Col
              span={24}
              style={{
                textAlign: 'center',
                padding: 10,
                display:
                  propsToRender?.length <= defaultPropertiesNum
                    ? 'none'
                    : 'block',
              }}
            >
              <Button
                small
                onClick={() =>
                  this.setState({
                    propertiesExpanded: !this.state.propertiesExpanded,
                  })
                }
              >
                {this.state.propertiesExpanded ? 'Show Less' : 'Show More'}
              </Button>
            </Col>
          </Col>
        </Row>
      );
    } else {
      return <Empty />;
    }
  }

  handleDoubleClick = (key: any) => {
    const { userReducer, record, alertMessage } = this.props;
    const { schema } = this.state;

    const column = schema?.columns.find(
      (col: SchemaColumnEntity) => col.name === key,
    );

    // System Admins can edit everything
    if (isSystemAdmin(userReducer)) {
      this.setState({ openedTooltip: key });
    }
    // For the rest we check if they can update the record and the column is not hidden
    else if (
      !isSystemAdmin(userReducer) &&
      canUserUpdateRecord(userReducer, schema, record) &&
      !column?.isHidden
    ) {
      this.setState({ openedTooltip: key });
    }
    // If the column is hidden, we show an error message
    else if (
      !isSystemAdmin(userReducer) &&
      canUserUpdateRecord(userReducer, schema, record) &&
      column?.isHidden
    ) {
      this.setState({ openedWarning: key });
    }
  };

  handleFieldChange = (newValue: any) => {
    this.setState({ changedProperty: newValue.value });
  };

  private renderDescriptionItemSimple(key: string, value: any) {
    const { columns } = this.props;

    const renderDescription = () => {
      const { schema } = this.state;
      const column = schema?.columns?.find(
        (col: SchemaColumnEntity) => col.name === key,
      );

      const handleFieldBlur = (): any => {
        const { record } = this.props;
        const initialValue = value;

        if (
          this.state.changedProperty !== undefined &&
          this.state.changedProperty !== initialValue &&
          this.state.schema &&
          record &&
          column?.type !== 'ENUM'
        ) {
          let properties: any = {};
          properties[this.state.openedTooltip as string] =
            this.state.changedProperty;
          updateRec(properties);
        } else {
          this.setState({
            changedProperty: undefined,
            openedTooltip: undefined,
          });
        }
      };

      const updateRec = (properties: any) => {
        const {
          record,
          updateRecord,
          onUpdate,
          hasColumnMappings,
          updateRecordAssociation,
        } = this.props;

        // a) Updating regular record
        if (this.state.schema && record && properties && !hasColumnMappings) {
          this.setState({ isSavingEdit: true });
          updateRecord(
            {
              schema: this.state.schema,
              recordId: record.id,
              createUpdate: {
                schemaId: this.state.schema?.id,
                entity: record?.entity,
                type: record?.type,
                properties,
              },
            },
            (res: any) => {
              this.setState({
                changedProperty: undefined,
                openedTooltip: undefined,
                isSavingEdit: false,
              });
              onUpdate && onUpdate();
            },
          );
        }
        // b) Updating a record with column mapping
        else if (
          this.state.schema &&
          record &&
          properties &&
          hasColumnMappings
        ) {
          updateRecordAssociation(
            {
              relatedEntityName: record.entity!.split(':')[1] || '',
              parentSchema: this.state.schema,
              recordId: record.id,
              dbRecordAssociationId: record.dbRecordAssociation!.id,
              createUpdate: {
                entity: record.entity || '',
                recordId: record.id,
                properties,
              },
            },
            (res: DbRecordEntityTransform) => {
              this.setState({
                changedProperty: undefined,
                openedTooltip: undefined,
                isSavingEdit: false,
              });
              onUpdate && onUpdate();
            },
          );
        }
      };

      const handleKeyDown = (e: any) => {
        if (e.key === 'Escape') {
          this.setState({
            changedProperty: undefined,
            openedTooltip: undefined,
          });
        } else if (e.key === 'Enter') {
          handleFieldBlur();
        }
      };

      const handleChange = (e: any) => {
        this.setState({ changedProperty: e.value });
        if (column?.type === 'BOOLEAN' || column?.type === 'ENUM') {
          let properties: any = {};
          properties[this.state.openedTooltip as string] = e.value;
          updateRec(properties);
        }
      };
      const linkedSchemaAssociation = schema?.associations?.find(
        (s: SchemaAssociationEntity) =>
          s.id === column?.linkedSchemaAssociationId,
      );
      const linkedSchemaTypesConstraint =
        linkedSchemaAssociation?.schemaTypesConstraints?.find(
          (c: any) => c.id === column?.linkedSchemaTypesConstraintId,
        );

      let field: FormField | undefined = undefined;

      if (schema && column) {
        field = {
          id: column?.id,
          schemaId: schema?.id,
          entity: schema?.moduleName,
          type: column?.type === 'BOOLEAN' ? 'BOOLEAN_INLINE' : column?.type,
          name: column?.name,
          label: column?.label || column?.name || '',
          description: column?.description,
          defaultValue: value,
          initialValue: value,
          options: column?.options,
          validators: column?.validators,
          isHidden: false,
          customValidation: undefined,
          isDisabled: this.state.isSavingEdit,
          handleInputChange: handleChange,
          linkedSchemaAssociation,
          linkedSchemaTypesConstraint,
          initialRecordMetadataLink: undefined,
          selected: this.props.record,
          // UI Overrides
          overrideRequired: undefined,
          // Other Overrides
          onBlur: handleFieldBlur,
          onKeyDown: handleKeyDown,
          hideLabel: true,
          autofocus: column?.type === 'LOOKUP',
          dateFormat:
            column?.type === 'DATE' || column?.type === 'DATE_TIME'
              ? column?.format
              : undefined,
        };
      }

      return (
        <Descriptions.Item
          key={key}
          span={this.getNumberOfColumns(columns!) === 4 ? 2 : 1}
          contentStyle={{
            width: this.getNumberOfColumns(columns!) === 4 ? '30%' : '50%',
          }}
          label={
            column && column.label
              ? changeToCapitalCase(column?.label)
              : changeToCapitalCase(key)
          }
        >
          <Tooltip
            title="Field is not editable"
            open={this.state.openedWarning === key}
            color="red"
          >
            {this.state.openedTooltip !== key ? (
              <div
                onDoubleClick={() => this.handleDoubleClick(key)}
                style={{ cursor: 'pointer', width: '100%' }}
              >
                {<span>{this.renderValue(key, value) || <>&nbsp;</>}</span>}
              </div>
            ) : (
              <Row>
                <Col span={24}>
                  {field ? (
                    renderFormField(field)
                  ) : (
                    <span>Field Undefined</span>
                  )}
                </Col>
              </Row>
            )}
          </Tooltip>
        </Descriptions.Item>
      );
    };

    /* We want to show coordinates for points, but exclude coordinates for lines and polygons (too long) */
    if (key === 'Coordinates' && value?.length < 3) {
      return renderDescription();
    }

    /* Don't show Files, we have other place to render them. */
    if (key !== 'Files') {
      return renderDescription();
    }
  }

  private showJSONInRawDataDrawer = (jsonValue: string) => {
    const { toggleDrawer, setRawDataDrawer } = this.props;

    setRawDataDrawer(jsonValue);
    toggleDrawer();
  };

  private renderValue(key: string, value: any) {
    const { record, userReducer } = this.props;
    const { schema } = this.state;

    if (schema) {
      const column = schema?.columns?.find(
        (elem: SchemaColumnEntity) => elem.name === key,
      );

      switch (column?.type) {
        case SchemaColumnTypes.JSON:
          return (
            <Button
              intent="primary"
              minimal
              onClick={() => this.showJSONInRawDataDrawer(value)}
            >
              View data
            </Button>
          );

        case SchemaColumnTypes.FILE_SINGLE:
          return <img style={{ height: 150, width: 150 }} src={value} />;

        case SchemaColumnTypes.PHONE_NUMBER_E164_GB:
          return <span>{value}</span>;
        case SchemaColumnTypes.PHONE_NUMBER:
          return <span>{value}</span>;

        case SchemaColumnTypes.ENUM:
          // For enum values we want to show the label instead of the value
          const option = column.options.find(
            (opt: SchemaColumnOptionEntity) => opt.value === value,
          );

          if (option) {
            return option.label;
          } else {
            return value;
          }

        case SchemaColumnTypes.BOOLEAN:
          return value === 'true' ? (
            <CheckSquareTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: '1.3em' }}
            />
          ) : (
            <CloseSquareTwoTone
              twoToneColor="#eb2f96"
              style={{ fontSize: '1.3em' }}
            />
          );

        case SchemaColumnTypes.LOOKUP:
          const link = record?.links?.find((l) => l.id === value);
          if (link) {
            return `${link.recordNumber ? `${link.recordNumber} - ` : ''}${
              link.title || ''
            }`;
          } else {
            return value;
          }

        // Currency fields should only be rendered if user has permission.
        case SchemaColumnTypes.CURRENCY:
          return hasPermissions(userReducer, 'financial.data.access')
            ? value
            : '*******';

        default:
          if (value !== undefined || value !== null) {
            // For cable length, shorten to two decimals and add unit
            if (key === 'CableLength') {
              return Number(value).toFixed(2) + ' m';
            } else {
              // If a value is a date/datetime, convert it
              if (dayjs(value, 'YYYY-MM-DD', true).isValid()) {
                return value;
              }
              // Else, just show the value
              else {
                return value;
              }
            }
          } else {
            return '-';
          }
      }
    } else {
      return <Spin size="small" />;
    }
  }

  render() {
    return this.renderListItemContent();
  }
}

const mapDispatch = (dispatch: any, ownProps: any) => ({
  alertMessage: (params: { body: string; type: string }) =>
    dispatch(displayMessage(params)),
  setRawDataDrawer: (params: string) =>
    dispatch(setRawDataDrawerContents(params)),
  toggleDrawer: () => dispatch(toggleRawDataDrawer()),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getSchemaById: (payload: IGetSchemaById, cb: any) =>
    dispatch(getSchemaByIdRequest(payload, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  updateRecordAssociation: (params: IUpdateRelatedRecordAssociation, cb: any) =>
    dispatch(updateRecordAssociationRequest(params, cb)),
});

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

export default connect(mapState, mapDispatch)(RecordProperties);
