import {
  Callout,
  DialogStep,
  MultistepDialog,
  Section,
  SectionCard,
} from '@blueprintjs/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { ISchemaReducer } from '../../../core/schemas/store/reducer';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { SchemaActionEntity } from '@d19n/models/dist/schema-manager/schema/action/schema.action.entity';
import { PipelineEntity } from '@d19n/models/dist/schema-manager/pipeline/pipeline.entity';
import { getSchemaFromShortListBySchemaId } from '../../utilities/schemaHelpers';
import { httpDelete, httpGet, httpPost, httpPut } from '../../http/requests';
import SchemaActionFlowStep from '../SchemaActionFlowStep';
import { PipelineStageEntity } from '@d19n/models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import {
  closeRecordForm,
  initializeRecordForm,
} from '../../../core/records/components/Forms/store/actions';
import { IGetSchemaById } from '@d19n/models/dist/rabbitmq/rabbitmq.interfaces';
import { getSchemaByIdRequest } from '../../../core/schemas/store/actions';
import { displayMessage } from '../../system/messages/store/reducers';
import { updateRecordInShortList } from '../../../core/records/store/actions';
import { getSchemaActionsForStageChangeFlow } from '../schemaActionFilters';
import { Link } from 'react-router-dom';

interface PropsType {
  record: DbRecordEntityTransform;
  targetStage: string | undefined;
  recordFormReducer: any;
  initializeForm: any;
  getSchemaById: (payload: any, cb: any) => void;
  openDialog?: boolean;
  onClose?: any;
  onConfirm?: any;
  closeForm: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  updateRecordInReducer: (params: { record: DbRecordEntityTransform }) => void;
  schemaReducer: ISchemaReducer;
}

const RecordStageChangeDialog: FunctionComponent<PropsType> = (props) => {
  const {
    record,
    targetStage,
    recordFormReducer,
    getSchemaById,
    updateRecordInReducer,
    openDialog,
    onClose,
    onConfirm,
    alertMessage,
    closeForm,
    schemaReducer,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true);
  const [isConfirmLoading, setIsConfirmLoading] =
    React.useState<boolean>(false);
  const [flowFormData, setFlowFormData] = useState<any[]>([]);
  const [isLoadingSchemaAction, setIsLoadingSchemaAction] =
    useState<boolean>(false);
  const [recordSchema, setRecordSchema] = React.useState<
    SchemaEntity | undefined
  >(undefined);
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>(
    undefined,
  );
  const [updateResults, setUpdateResults] = useState<any[]>([]);
  const [createResults, setCreateResults] = useState<any[]>([]);
  const [schemaActionFlow, setSchemaActionFlow] = React.useState<
    SchemaActionEntity | undefined
  >(undefined);

  function resetState() {
    setIsNextDisabled(true);
    setIsConfirmLoading(false);
    setCreateResults([]);
    setUpdateResults([]);
  }

  const closeDialog = () => {
    onClose && onClose();
    resetState();
    setIsDialogOpen(false);
    setFlowFormData([]);
    setSchemaActionFlow(undefined);
    setCreateResults([]);
    setUpdateResults([]);
  };

  useEffect(() => {
    if (openDialog && record) {
      setIsDialogOpen(openDialog);
      closeForm();
      resetState();
      fetchSchema();
    }
  }, [openDialog, record]);

  const fetchSchema = () => {
    const shortlistSchema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      record.schemaId,
    );
    if (shortlistSchema) {
      setRecordSchema(shortlistSchema);
    } else {
      getSchemaById({ schemaId: record.schemaId }, (schema: any) => {
        setRecordSchema(schema);
      });
    }
  };

  useEffect(() => {
    if (recordSchema && targetStage && openDialog) {
      fetchSchemaActionFlows();
      fetchPipeline();
    }
  }, [recordSchema, openDialog]);

  const fetchPipeline = () => {
    if (recordSchema) {
      httpGet(
        `SchemaModule/v1.0/pipelines/bymodule/${recordSchema.moduleName}/${
          recordSchema.entityName
        }${record.type ? `?schemaType=${record.type}` : '?schemaType='}`,
      ).then((res: any) => {
        const pipelines = res.data?.data || [];
        if (pipelines.length > 0) {
          setPipeline(pipelines[0]);
        }
      });
    }
  };

  // Fetch all schema actions and apply a rule set
  function fetchSchemaActionFlows() {
    if (record) {
      setIsLoadingSchemaAction(true);
      setSchemaActionFlow(undefined);
      httpGet(`SchemaModule/v1.0/schemas-actions`)
        .then((res) => {
          setIsLoadingSchemaAction(false);

          let filteredSchemaActionFlows: SchemaActionEntity[] =
            getSchemaActionsForStageChangeFlow(
              res.data?.data,
              record,
              recordSchema!,
              targetStage!,
            );

          if (filteredSchemaActionFlows.length > 0) {
            console.log(
              '%cdebug: Record stage change: Filtered schema action flows',
              'color:limegreen',
              filteredSchemaActionFlows,
            );
            setSchemaActionFlow(filteredSchemaActionFlows[0]);
          } else {
            console.log(
              '%cdebug: No schema action flows found.',
              'color:orange',
            );
          }
        })
        .catch((err) => {
          console.error('Error loading table data:', err);
        });
    }
  }

  function setupStep(newStep: any) {
    if (newStep === 0) {
      setFlowFormData([]);
      closeForm();
    } else if (
      newStep > 0 &&
      newStep <= schemaActionFlow?.definition?.dialogSteps?.length
    ) {
      let newFlowFormData: any[] = flowFormData.slice(0, Number(newStep) - 1);
      newFlowFormData.push(recordFormReducer);
      setFlowFormData(newFlowFormData);
      closeForm();
    }
  }

  // Debug
  // useEffect(() => {
  //   console.log('%cdebug: flowData updated', 'color:royalblue', flowFormData);
  // }, [flowFormData]);

  // Some schema action flows can pass custom URLs to be executed after the flow is completed. We pass the creates and updates to the custom URL endpoint.
  const handleCustomURLs = async (creates: any[], updates: any[]) => {
    const shouldShowSummaryStep =
      schemaActionFlow?.definition?.settings?.showSummaryStep;
    const onSubmitUrl = schemaActionFlow?.definition?.onSubmitUrl;

    if (schemaActionFlow && onSubmitUrl) {
      try {
        let URL = onSubmitUrl.url;

        // Replace source record id if asked in schema configuration
        if (URL && URL.includes('{source_record_id}')) {
          URL = URL.replace('{source_record_id}', record.id);
        }

        // Support POST, PUT, DELETE methods but fallback to POST
        if (onSubmitUrl.method === 'post') {
          await httpPost(URL, {
            creates: creates,
            updates: updates,
          }).then((res: any) => {
            if (res.data?.data?.length! > 0) {
              setCreateResults(res.data.data);
            }
          });
        } else if (onSubmitUrl.method === 'put') {
          await httpPut(URL, {
            creates: creates,
            updates: updates,
          });
        } else if (onSubmitUrl.method === 'delete') {
          await httpDelete(URL);
        } else {
          await httpPost(URL, {
            creates: creates,
            updates: updates,
          });
        }
      } catch (err: any) {
        alertMessage({
          body: 'Could not execute custom URL endpoint',
          type: 'error',
        });
        return true;
      }
    }
  };

  async function handleFinalStepSubmit() {
    setIsConfirmLoading(true);

    try {
      // If we're running a regular schema action flow
      if (schemaActionFlow) {
        const onSubmitUrl = schemaActionFlow?.definition?.onSubmitUrl;
        const shouldSkipStageUpdate =
          schemaActionFlow?.definition?.settings?.skipStageUpdate;
        const shouldShowSummaryStep =
          schemaActionFlow?.definition?.settings?.showSummaryStep;

        let createPayload: any[] = [];
        let updatePayload: any[] = [];

        // Group update and create steps
        let updateSteps = flowFormData.filter(
          (formReducerSnapshot: any) =>
            formReducerSnapshot.schema?.id === record.schemaId &&
            formReducerSnapshot.isUpdateReq,
        );
        let createSteps = flowFormData.filter(
          (formReducerSnapshot: any) => formReducerSnapshot.isCreateReq,
        );

        // Extract data from update steps and pass it to the update endpoint
        if (updateSteps.length > 0) {
          updatePayload = updateSteps.map((formReducerSnapshot: any) => {
            return {
              id: record.id,
              entity: record.entity,
              type: record.type,
              schemaTypeId:
                formReducerSnapshot?.custom?.schemaAction?.schemaTypeId || null,
              stageKey: !shouldSkipStageUpdate ? targetStage : null,
              properties: formReducerSnapshot.modified[0].properties,
            };
          });
          console.log('debug: UPDATE PAYLOAD', updatePayload);

          if (!onSubmitUrl) {
            await httpPut(`${recordSchema?.moduleName}/v1.0/db/bulk-update`, {
              recordsToUpdate: updatePayload,
            }).then((res: any) => {
              if (res.data?.data?.length! > 0) {
                setUpdateResults(res.data.data);
              }
            });
            const updatedRecord = await httpGet(
              `${recordSchema?.moduleName}/v1.0/db/${recordSchema?.entityName}/${record.id}`,
            );
            updateRecordInReducer({ record: updatedRecord.data.data });
          }
        }

        // If no update steps are present, we still need to update the record to the target stage
        else if (updateSteps.length === 0 && onSubmitUrl) {
          updatePayload.push({
            id: record.id,
            entity: record.entity,
            type: record.type,
            stageKey: targetStage,
            properties: record.properties,
          });
        }

        // 2. Extract data from create steps and pass it to the upsert endpoint
        if (createSteps.length > 0) {
          createPayload = createSteps.map(
            (formReducerSnapshot: any, i: number) => {
              return {
                entity: `${formReducerSnapshot.schema?.moduleName}:${formReducerSnapshot.schema?.entityName}`,
                type: formReducerSnapshot?.recordType,
                properties: formReducerSnapshot.modified[0]?.properties,
                schemaId: formReducerSnapshot.schema?.id,
                schemaActionId: formReducerSnapshot.schemaActionId,
                schemaTypeId:
                  formReducerSnapshot?.custom?.schemaAction?.schemaTypeId ||
                  null,
                associations: [
                  ...formReducerSnapshot.modified[0]?.associations,
                  {
                    entity: record?.entity,
                    recordId: record?.id,
                    linkType: formReducerSnapshot?.custom?.linkType,
                    relationType:
                      formReducerSnapshot.schema?.id === record?.schemaId
                        ? 'PARENT'
                        : null,
                  },
                ],
              };
            },
          );

          console.log('debug: CREATE PAYLOAD', createPayload);

          // If no external URL is provided, launch regular bulk upsert
          if (!onSubmitUrl) {
            await httpPost(`${recordSchema?.moduleName}/v1.0/db/bulk-upsert`, {
              recordsToUpsert: createPayload,
              options: {
                linkRelatedRecordsAfterUpsert: true,
              },
            }).then((res: any) => {
              if (res.data?.data?.creates?.length! > 0) {
                setCreateResults(res.data.data.creates);
              }
              if (res.data?.data?.updates?.length! > 0) {
                setUpdateResults(res.data.data.updates);
              }
            });
          }
        }

        // 3. Handle custom URL endpoints
        if (onSubmitUrl) {
          handleCustomURLs(createPayload, updatePayload);
        }

        // If we're not showing a summary step, close the dialog
        if (!shouldShowSummaryStep) {
          closeDialog();
        }
      }
      // Else, there's no schema action flow, so just update the source record to the target stage
      else {
        await httpPut(`${recordSchema?.moduleName}/v1.0/db/bulk-update`, {
          recordsToUpdate: [
            {
              id: record.id,
              entity: record.entity,
              type: record.type,
              stageKey: targetStage,
              properties: record.properties,
            },
          ],
        }).then((res: any) => {
          // if (res.data?.data?.length! > 0) {
          //   setUpdateResults(res.data.data);
          // }
          closeDialog();
        });

        const updatedRecord = await httpGet(
          `${recordSchema?.moduleName}/v1.0/db/${recordSchema?.entityName}/${record.id}`,
        );
        updateRecordInReducer({ record: updatedRecord.data.data });
      }

      alertMessage({
        body: 'Record stage updated successfully',
        type: 'success',
      });

      if (onConfirm) {
        onConfirm();
      }
    } catch (err: any) {
      setIsConfirmLoading(false);
      console.log('debug: error', err);
      alertMessage({
        body: err?.response?.data?.message,
        type: 'error',
      });
    }
  }

  // Render each step as a DialogStep
  const renderSchemaActionSteps = () => {
    const steps = schemaActionFlow?.definition?.dialogSteps || [];

    if (steps.length > 0) {
      return steps.map((step: any, index: number) => {
        return (
          <DialogStep
            title={step.name}
            id={index}
            key={index}
            panel={
              <Section style={{ overflowY: 'auto' }}>
                <SectionCard>
                  <SchemaActionFlowStep
                    step={step}
                    sourceRecord={record}
                    isNextDisabled={(isNextDisabled: boolean) => {
                      setIsNextDisabled(isNextDisabled);
                    }}
                    key={index}
                  />
                </SectionCard>
              </Section>
            }
          />
        );
      });
    } else {
      return (
        <Callout intent="danger">No schema action flow steps defined.</Callout>
      );
    }
  };

  const getPipelineNameByKey = (key: string | undefined): string => {
    if (pipeline && key) {
      const pipelineStage = pipeline?.stages?.find(
        (stage: PipelineStageEntity) => stage.key === key,
      );
      return pipelineStage ? pipelineStage.name : '';
    } else {
      return '';
    }
  };

  return (
    <>
      <MultistepDialog
        title={
          !createResults.length && !updateResults
            ? `Updating Stage (${getPipelineNameByKey(
                record.stage?.key,
              )} → ${getPipelineNameByKey(targetStage)})`
            : 'Updated stage'
        }
        isOpen={isDialogOpen}
        style={{
          minWidth: isMobile ? 'auto' : 800,
          width: isMobile ? '100%' : '50%',
        }}
        canOutsideClickClose={false}
        showCloseButtonInFooter={true}
        icon="info-sign"
        navigationPosition="top"
        initialStepIndex={0}
        backButtonProps={{
          disabled: createResults.length > 0 || updateResults.length > 0,
        }}
        onClose={closeDialog}
        usePortal={true}
        onChange={(newStep: string) => {
          setupStep(newStep);
          setIsNextDisabled(true);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
        }}
        finalButtonProps={{
          disabled: isConfirmLoading,
          onClick: () => handleFinalStepSubmit(),
          text: 'Finish',
        }}
        lazy
      >
        {/* Render dynamic schema action steps */}
        {renderSchemaActionSteps()}

        {/* Confirmation step */}
        <DialogStep
          title="Confirmation"
          id={schemaActionFlow?.definition?.dialogSteps?.length}
          key="confirmation"
          panel={
            <Section style={{ overflowY: 'auto' }}>
              <SectionCard>
                {/* Show Confirmation */}
                {!createResults.length && !updateResults.length && (
                  <Callout icon="tick-circle" title="Confirmation Step">
                    <span>
                      {schemaActionFlow?.definition?.settings
                        ?.skipStageUpdate ? (
                        <span>
                          Please confirm that you want to finish the update
                          process.
                        </span>
                      ) : (
                        <span>
                          Please confirm that you want to move the record to{' '}
                          {targetStage} stage.
                        </span>
                      )}
                    </span>
                  </Callout>
                )}

                {/* Create Results */}
                {createResults.length > 0 && (
                  <Callout intent="success" icon={null} title="Created Records">
                    <ul>
                      {createResults?.map((result: any, index: number) => {
                        return (
                          <li key={index}>
                            <Link
                              to={`/${result.entity.split(':')[0]}/${
                                result.entity.split(':')[1]
                              }/${result.id}`}
                              target="_blank"
                            >
                              <span>{result.entity.split(':')[1]}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </Callout>
                )}

                {/* Update Results */}
                {updateResults.length > 0 && (
                  <Callout intent="primary" icon={null} title="Updated Records">
                    <ul>
                      {updateResults?.map((result: any, index: number) => {
                        return (
                          <li key={index}>
                            <Link
                              to={`/${result.entity.split(':')[0]}/${
                                result.entity.split(':')[1]
                              }/${result.id}`}
                              target="_blank"
                            >
                              <span>{result.entity.split(':')[1]}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </Callout>
                )}
              </SectionCard>
            </Section>
          }
        />
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getSchemaById: (payload: IGetSchemaById, cb: any) =>
    dispatch(getSchemaByIdRequest(payload, cb)),
  alertMessage: (params: { body: string; type: string }) =>
    dispatch(displayMessage(params)),
  updateRecordInReducer: (params: { record: DbRecordEntityTransform }) =>
    dispatch(updateRecordInShortList(params)),
  closeForm: () => dispatch(closeRecordForm()),
});

export default connect(mapState, mapDispatch)(RecordStageChangeDialog);
