import {MessageOutlined, PoweroffOutlined, UserOutlined,} from '@ant-design/icons';
import {Layout, Menu} from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import {connect} from 'react-redux';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import './App.scss';
import CacheBuster from './CacheBuster';
import {CrmModuleNavigationMenu, CrmModuleRoutes,} from './containers/CrmModule/containers/CrmModuleNavigationMenu';
import {
    FieldServiceModuleNavigationMenu,
    FieldServiceModuleRoutes,
} from './containers/FieldServiceModule/FieldServiceModuleNavigationMenu';
import Login from './containers/IdentityManager/containers/UserLogin';
import ForgotPassword from './containers/IdentityManager/containers/UserLogin/containers/ForgotPassword';
import ResetPassword from './containers/IdentityManager/containers/UserLogin/containers/ResetPassword';
import ModuleSelector from './containers/ModuleSelector';
import {OrderModuleRoutes} from './containers/OrderModule/OrderModuleNavigationMenu';
import {logoutRequest} from './core/identity/store/actions';
import permissionCheck from './shared/permissions/permissionCheck';
import Message from './shared/system/messages';
import Notification from './shared/system/notifications';
import history from './shared/utilities/browserHisory';
import OdinHelmet from './core/navigation/OdinHelmet';
import MFASettings from './containers/IdentityManager/containers/UserLogin/containers/MFASettings';
import {Freshchat} from 'reactjs-freshchat';
import {useEffect} from "react";
import {getOdinSchemaByEntity} from "./shared/utilities/schemaHelpers";
import {SchemaModuleTypeEnums} from "@d19n/models/dist/schema-manager/schema/types/schema.module.types";
import {SchemaModuleEntityTypeEnums} from "@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types";
import * as Sentry from '@sentry/react';
import {getEnvironmentName} from "./shared/utilities/userInterfaceHelpers";
import UserSync from "./containers/IdentityManager/UserSync";

const {Header} = Layout;

const {SCHEMA_MODULE} = SchemaModuleTypeEnums
const {FILE} = SchemaModuleEntityTypeEnums

interface Props {
    identityReducer: any;
    logout: any;
    userReducer: any;
}

function App(props: Props) {
    const {identityReducer, logout, userReducer} = props;
    const userToken = localStorage.getItem(`token`);

    // On Application mount, fetch the file schema, if not already in the schema reducer
    useEffect(() => {
        getFileSchemaAndStoreToReducer()
    }, []);
    const getFileSchemaAndStoreToReducer = async () => {
        const fileSchema = await getOdinSchemaByEntity(
            SCHEMA_MODULE, FILE
        )
    }


    //  Initialize Sentry if it is not already initialized
    useEffect(() => {
        if (userReducer && !Sentry.getCurrentHub()?.getClient() && window) {
            initializeSentry();
        }
    }, []);
    // Initialize Sentry only for environments other than localhost
    const initializeSentry = () => {
        const environment = getEnvironmentName(window);
        if (environment && (environment !== 'localhost')) {
            console.log('%cSentry initialized', 'color: lime');
            Sentry.init({
                dsn: 'https://c55c9112314d8b691be9bc843c9a7e90@o916048.ingest.us.sentry.io/4507458149285888',
                environment: environment,
                integrations: [Sentry.browserTracingIntegration],
                tracesSampleRate: 1.0,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
                initialScope: {
                    user: {
                        id: userReducer?.user?.id,
                        email: userReducer?.user?.email,
                        name: `${userReducer?.user?.firstname} ${userReducer?.user?.lastname}`,
                    },
                },
            });
        }
    };

    return (
        <div className="app-container">
            <Layout className="page-layout">
                <Notification/>
                {userToken ? (
                    <Freshchat
                        token={process.env.REACT_APP_FRESHCHAT_TOKEN!}
                        host={process.env.REACT_APP_FRESHCHAT_HOST!}
                        externalId={userReducer?.user?.id}
                        email={userReducer?.user?.email}
                        firstName={userReducer?.user?.firstname}
                        lastName={userReducer?.user?.lastname}
                        config={{
                            headerProperty: {
                                hideChatButton: true,
                                backgroundColor: '#2e598a',
                                foregroundColor: '#ffffff',
                            },
                        }}
                    />
                ) : (
                    <></>
                )}
                <Message/>

                {permissionCheck.hasModuleSelectorAccess(identityReducer) ? (
                    <Router history={history}>
                        <UserSync/>
                        <CacheBuster/>
                        <OdinHelmet/>
                        {/*<NavigationHistoryTabs/>*/}
                        <Header className="header">
                            <Menu theme="dark" mode="horizontal" selectable={false}>
                                <Menu.Item
                                    key="app"
                                    onClick={() => history.push('/')}
                                    style={{fontWeight: 700, color: '#fff'}}
                                >
                                    {process.env.REACT_APP_ODIN_APP_NAME || 'Odin'}
                                </Menu.Item>
                                {CrmModuleNavigationMenu()}
                                {FieldServiceModuleNavigationMenu()}

                                <SubMenu
                                    key="userSubmenu"
                                    icon={
                                        <UserOutlined
                                            style={{
                                                border: '1px solid #ffffffa6',
                                                padding: 3,
                                                margin: 0,
                                                borderRadius: '100%',
                                            }}
                                        />
                                    }
                                >
                                    <Menu.Item>
                                        <span>{`${identityReducer?.user?.firstname} ${identityReducer?.user?.lastname}`}</span>
                                    </Menu.Item>
                                    <Menu.Item>
                    <span>
                      {process.env.REACT_APP_VERSION ? (
                          <span>Version: {process.env.REACT_APP_VERSION}</span>
                      ) : (
                          <span>Version: -</span>
                      )}
                    </span>
                                    </Menu.Item>

                                    {/* Chat */}
                                    <Menu.Item
                                        key="chat"
                                        onClick={() => {
                                            (window! as any)?.fcWidget.open();
                                        }}
                                    >
                                        <MessageOutlined style={{marginRight: 8}}/> Start Chat
                                    </Menu.Item>

                                    {/* Log out */}
                                    <Menu.Item
                                        key="UserSettings"
                                        onClick={() => logout()}
                                        style={{color: '#ff6464'}}
                                    >
                                        <PoweroffOutlined/> <span>Logout</span>
                                    </Menu.Item>
                                </SubMenu>
                            </Menu>
                        </Header>
                    </Router>
                ) : (
                    <Header className="header">
                        <Menu theme="dark" mode="horizontal" selectable={false}>
                            <Menu.Item
                                style={{float: 'right'}}
                                key="UserSettings"
                                onClick={() => logout()}
                            >
                                <span>Logout</span>
                            </Menu.Item>
                        </Menu>
                    </Header>
                )}
                <Router history={history}>
                    <Switch>
                        <ProtectedModuleSelector
                            identityReducer={identityReducer}
                            exact
                            path="/"
                        >
                            <ModuleSelector/>
                        </ProtectedModuleSelector>

                        <Route path="/login" exact>
                            <Login/>
                        </Route>

                        <Route path="/login/mfa" exact>
                            <MFASettings/>
                        </Route>

                        {CrmModuleRoutes}
                        {FieldServiceModuleRoutes}
                        {OrderModuleRoutes}
                        <Route exact path={`/forgot-password`} component={ForgotPassword}/>
                        <Route
                            exact
                            path={`/reset-password/:token`}
                            component={ResetPassword}
                        />
                    </Switch>
                </Router>
            </Layout>
        </div>
    );
}

// @ts-ignore
function ProtectedModuleSelector({children, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) =>
                permissionCheck.hasModuleSelectorAccess(rest.identityReducer) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: location},
                        }}
                    />
                )
            }
        />
    );
}

// @ts-ignore
function ProtectedCrmModule({children, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) =>
                permissionCheck.hasCrmAccess(rest.identityReducer) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: location},
                        }}
                    />
                )
            }
        />
    );
}

const mapState = (state: any) => ({
    identityReducer: state.identityReducer,
    userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
    logout: () => dispatch(logoutRequest()),
});

export default connect(mapState, mapDispatch)(App);
