import { Button, Callout } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaActionEntity } from '@d19n/models/dist/schema-manager/schema/action/schema.action.entity';
import { IGetSchemaById } from '@d19n/models/dist/rabbitmq/rabbitmq.interfaces';
import { getSchemaByIdRequest } from '../../../core/schemas/store/actions';
import {
  closeRecordForm,
  initializeRecordForm,
} from '../../../core/records/components/Forms/store/actions';
import { httpGet } from '../../http/requests';
import { isMobile } from 'react-device-detect';
import { isSchemaActionPropertyFilterMatching } from '../helpers';
import CoreForm from '../../../core/records/components/Forms/CoreForm';

interface Props {
  step: any;
  schemaReducer: any;
  isNextDisabled?: Function;
  onSuccess?: (record: any) => void;
  closeForm: () => void;
  initializeForm: (params: any) => void;
  getSchemaById: (payload: any, cb: any) => void;
  sourceRecord?: DbRecordEntityTransform;
}

const uuid = uuidv4();

const SchemaActionFlowStep: React.FC<Props> = (props: Props) => {
  const {
    step,
    isNextDisabled,
    onSuccess,
    closeForm,
    initializeForm,
    getSchemaById,
    sourceRecord,
  } = props;
  const [schemaActions, setSchemaActions] = useState<SchemaActionEntity[]>([]);
  const [selectedSchemaAction, setSelectedSchemaAction] = useState<
    SchemaActionEntity | undefined
  >(undefined);

  // When step changes / comes into component, fetch schema actions
  useEffect(() => {
    if (step && step.schemaActions) {
      getSchemaActionsByNames(step.schemaActions);
    }
  }, [step]);

  // Get schema actions by name(s)
  const getSchemaActionsByNames = (names: string[]) => {
    if (names.length > 0) {
      httpGet(`SchemaModule/v1.0/schemas-actions`).then((res: any) => {
        let filteredActions =
          res.data?.data?.filter((action: any) =>
            names.includes(action.name),
          ) || [];

        // Filter actions based on property filters, if set into schema action
        filteredActions = filteredActions.filter(
          (action: SchemaActionEntity) => {
            const propertyFilters = action.definition?.propertyFilters;
            if (propertyFilters && propertyFilters?.length! > 0) {
              return isSchemaActionPropertyFilterMatching(
                sourceRecord?.properties,
                propertyFilters,
              );
            } else {
              return action;
            }
          },
        );

        // If there's only 1 action, initialize it immediately
        if (filteredActions.length === 1) {
          initializeAction(filteredActions[0]);
        }
        // Else, show a choice to select from
        else if (filteredActions.length > 1) {
          setSchemaActions(filteredActions);
        }
      });
    }
  };

  const renderSchemaActionsSelection = () => {
    return schemaActions.map((action: SchemaActionEntity) => (
      <Col span={isMobile ? 24 : 12} key={action.id}>
        <Button
          intent="primary"
          fill
          style={{ marginBottom: 10 }}
          onClick={() => {
            initializeAction(action);
          }}
          text={action.name}
        />
      </Col>
    ));
  };

  const initializeAction = (schemaAction: SchemaActionEntity) => {
    closeForm();
    setSelectedSchemaAction(schemaAction);
    getSchemaById({ schemaId: schemaAction.schemaId }, (schema: any) => {
      initializeForm({
        formUUID: uuid,
        title: schemaAction.name,
        showFormModal: true,
        isCreateReq: schemaAction.isCreate,
        isUpdateReq: schemaAction.isUpdate,
        schema: schema!,
        sections: [{ name: schema?.name, schema: schema }],
        schemaActionId: schemaAction?.id,
        selected: schemaAction.isUpdate ? sourceRecord : null,
        // Once we introduce proper typing to form reducer, we can use
        // custom object to pass custom information to the form as we see fit.
        custom: {
          linkType: step?.linkType || null,
          schemaAction: schemaAction,
        },
      });
    });
  };

  return (
    <Row>
      <Col span={24}>
        {/* If there are more than 1 schema actions, show a selector */}
        {!selectedSchemaAction && schemaActions.length > 1 && (
          <Row gutter={8}>
            <Col span={24} style={{ marginBottom: 15 }}>
              <Callout title="Select schema action">
                Select one form from the list below.
              </Callout>
            </Col>
            {renderSchemaActionsSelection()}
          </Row>
        )}
      </Col>

      {/* Show Schema Action name and back button if needed */}
      {selectedSchemaAction && (
        <Col span={24} style={{ marginBottom: 15 }}>
          <Row align="middle">
            <Col span={18}>
              <span style={{ fontSize: '1.3em', fontWeight: 600 }}>
                {selectedSchemaAction?.name}
              </span>
            </Col>
            {schemaActions.length > 1 && (
              <Col span={6}>
                <Button
                  intent="primary"
                  fill
                  onClick={() => {
                    setSelectedSchemaAction(undefined);
                    closeForm();
                  }}
                  outlined
                  icon="caret-left"
                  text="Return to list"
                />
              </Col>
            )}
          </Row>
        </Col>
      )}

      <CoreForm
        type="EMBEDDED"
        formUUID={uuid}
        isCreateRecord={true}
        showFormActions={false}
        isNextDisabled={isNextDisabled}
        onSubmitEvent={(e: any) => {
          onSuccess && onSuccess(e);
        }}
      />
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  getSchemaById: (payload: IGetSchemaById, cb: any) =>
    dispatch(getSchemaByIdRequest(payload, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  closeForm: () => dispatch(closeRecordForm()),
});

export default connect(mapState, mapDispatch)(SchemaActionFlowStep);
